import { useEffect, useState } from "react";

/**
 * 指定した画像データの Data URL を作成します。
 *
 * その画像データが他のコンポーネントでも利用されていた場合はキャッシュします。
 * どこからも利用されなくなったら破棄します。
 *
 * @param blob 画像データ
 * @returns 画像データの Data URL
 */
export function useObjectURL<T extends Blob | MediaSource | undefined>(
    blob: T,
): T extends undefined ? undefined : string {
    const [objectUrl, setObjectUrl] = useState(() =>
        // ここは状況によって何度も呼ばれる (useEffect が呼ばれる回数と一致しない)
        // そのため URL 利用者としてカウントしない
        blob === undefined ? undefined : getOrCreateCacheEntry(blob).url,
    );

    // Object URL を初期化する
    useEffect(() => {
        if (blob === undefined) {
            return undefined;
        }

        const entry = getOrCreateCacheEntry(blob);
        entry.count += 1;

        setObjectUrl(entry.url);

        return () => {
            entry.count -= 1;
            deleteIfNoUse(entry);
        };
    }, [blob]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return objectUrl as any;
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

interface ObjectUrlEntry {
    readonly blob: Blob | MediaSource;
    readonly url: string;
    count: number;
}

const Cache = new Map<Blob | MediaSource, ObjectUrlEntry>();

/**
 * キャッシュ エントリを作成します。
 *
 * @param blob 画像データ
 * @returns キャッシュ エントリ
 */
function getOrCreateCacheEntry(blob: Blob | MediaSource): ObjectUrlEntry {
    let entry = Cache.get(blob);
    if (entry === undefined) {
        entry = { blob, count: 0, url: URL.createObjectURL(blob) };
        Cache.set(blob, entry);
    }
    return entry;
}

/**
 * キャッシュ エントリの URL が使用されていなければ、エントリを削除します。
 *
 * @param entry キャッシュ エントリ
 */
function deleteIfNoUse(entry: ObjectUrlEntry): void {
    if (entry.count === 0) {
        Cache.delete(entry.blob);
        URL.revokeObjectURL(entry.url);
    }
}
